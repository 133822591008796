import { ParsedUrlQuery } from 'querystring';

import { FilterItem, FilterPageConfigItem } from './types';

export const getKeyValue = <T extends Record<string, unknown>, U extends keyof T>(obj: T) => (
  key: U
) => obj[key];

export const isKeyPresentInUrl = (
  query: ParsedUrlQuery,
  filterKey: string,
  target: string
): boolean => {
  return query[filterKey]
    ? query[filterKey] instanceof Array
      ? (query[filterKey] as Array<string>).includes(target)
      : query[filterKey] === target
    : false;
};

export function createFilterItems(
  items: Array<FilterItem | string>,
  filterKey: string,
  totalFacets: Record<string, Record<string, number>>,
  facets: Record<string, Record<string, number>>,
  query: ParsedUrlQuery
): Array<FilterItem> {
  if (query[filterKey]) {
    return items.map((item) => {
      if (typeof item === 'string') {
        return {
          label: item,
          value: item,
          counter: facets[filterKey] ? facets[filterKey][item] || 0 : 0,
          checked: isKeyPresentInUrl(query, filterKey, item),
        };
      } else {
        return {
          label: item.label,
          value: item.value,
          counter: facets[filterKey] ? facets[filterKey][item.value] || 0 : 0,
          checked: isKeyPresentInUrl(query, filterKey, item.value),
        };
      }
    });
  } else {
    const t = items.map((item) => {
      if (typeof item === 'string') {
        return {
          label: item,
          value: item,
          counter: totalFacets[filterKey] ? totalFacets[filterKey][item] || 0 : 0,
          checked: isKeyPresentInUrl(query, filterKey, item),
        };
      } else {
        return {
          label: item.label,
          value: item.value,
          counter: totalFacets[filterKey] ? totalFacets[filterKey][item.value] || 0 : 0,
          checked: isKeyPresentInUrl(query, filterKey, item.value),
        };
      }
    });
    return t;
  }
}

export const deleteFilterFromConfig = (
  filtersData: Array<FilterPageConfigItem>,
  skipFilterKeys: Array<string>
) =>
  filtersData.filter(({ key }) => {
    return !skipFilterKeys?.includes(key);
  });

export const getSelectedFiltersList = (
  filters: Record<string, Array<FilterItem>>,
  skipFilterKeys?: Array<string>
) =>
  Object.entries(filters)
    .map(([key]) =>
      filters[key].filter((item) => item.checked === true).map((item) => ({ ...item, key }))
    )
    .flat(1)
    .filter(({ key }) => !skipFilterKeys?.includes(key));

export const getSearchParams = (query: ParsedUrlQuery, resetPage?: boolean, limit = 5) => {
  const params = new URLSearchParams();
  params.append('limit', String(limit));
  Object.entries(query).map(([key, value]) => {
    if (value instanceof Array) {
      value.length > 1
        ? value.forEach((element, idx) => params.append(`${key}[${idx}]`, element))
        : value[0] && params.append(key, value[0]);
    } else {
      value && params.append(key, value);
    }
  });
  if (resetPage) params.set('page', '1');
  if (params.has('page')) params.set('page', String(Number(params.get('page')) - 1));
  return params;
};
